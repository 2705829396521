import { store } from 'redux-tk/store';
import { allUserRoles } from './constants';

interface roleObject {
  role: allUserRoles;
  higherRoles: Array<allUserRoles>;
}

const getRoleCombos = (currentRole: allUserRoles) => {
  const roleCombos: roleObject = {
    Admin: { role: allUserRoles.Admin, higherRoles: [allUserRoles.Developer] },
    Support: {
      role: allUserRoles.Support,
      higherRoles: [allUserRoles.Developer, allUserRoles.Admin],
    },
    User: {
      role: allUserRoles.User,
      higherRoles: [allUserRoles.Developer, allUserRoles.Admin, allUserRoles.Support],
    },
    Developer: { role: allUserRoles.Developer, higherRoles: [] },
  }[currentRole];

  return roleCombos;
};

export const checkAuth = () => {
  const state = store.getState();

  console.log('state.currentUser.user.id', state.currentUser.user.Id);

  if (state.currentUser.user.Id !== 0) return true;

  return false;
};

const validRole = (userRole: allUserRoles, componentRole: allUserRoles) => {
  const minRoleCombo = getRoleCombos(userRole);

  if (!minRoleCombo) return false;

  if (minRoleCombo.higherRoles.length === 0) return true;

  if (userRole === componentRole) return true;
  else if (minRoleCombo.higherRoles.includes(componentRole)) return false;

  return true;
};

export const checkRole = (componentRole: allUserRoles) => {
  if (!checkAuth()) {
    return false;
  }

  const state = store.getState();

  try {
    const role = state.currentUser.user.role;

    if (!validRole(role, componentRole)) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};
