import { backendUrl } from 'config/constants';
import { MethodTypes } from 'hooks/useAxios';
import useForm from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import { ApplicationUser, switchUser } from 'redux-tk/slices/userSlice';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { allUserRoles } from 'utilities/constants';

type TrainingUserLogin = {
  userNameOrEmail: string;
  password: string;
};

export const Login: React.FC = () => {
  const [loginState, setLoginState] = useState<Boolean>(true);
  const { handleSubmit, handleChange, handleBlur, values, errors, axiosState } = useForm<
    TrainingUserLogin,
    ApplicationUser
  >(`${backendUrl}login`, MethodTypes.POST, {
    userNameOrEmail: '',
    password: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (axiosState.status !== 0 && axiosState.status !== 200) {
      // if success take user data from BE and set it on redux state
      // if invalid then set form status to invalid and set a custom message.
      setLoginState(false);
    } else if (axiosState.data !== null) {
      const role = axiosState.data.role as allUserRoles;
      const user: ApplicationUser = {
        role,
        ...axiosState.data,
      };

      console.log('User msse', user);

      dispatch(switchUser(user));

      // note sure if need to change path here?
      // set the state object and change route to a main login page
    }
  }, [axiosState, dispatch, loginState]);

  return (
    <Wrapper>
      <Main>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Email or userName</Label>
            <Input
              type="text"
              placeholder="Email or UserName"
              name="userNameOrEmail"
              id="userNameOrEmail"
              value={values.userNameOrEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.userNameOrEmail}
            />
            {errors.userNameOrEmail && <Error>{errors.userNameOrEmail}</Error>}
          </FormGroup>

          <FormGroup>
            <Label>Password</Label>

            <Input
              type="password"
              placeholder="password"
              name="password"
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.password}
            />
            {errors.password && <Error>{errors.password}</Error>}
          </FormGroup>
          <LoginButton type="submit">Login</LoginButton>
        </Form>
        {!loginState && <div>User or password invalid</div>}
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const Main = styled.div`
  width: 450px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(161, 108, 40, 1);
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  color: black;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input<{ isInvalid: boolean }>`
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : 'rgba(161, 108, 40, 1)')};
  border-radius: 5px;
  box-sizing: border-box;
`;

const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const LoginButton = styled.button`
  background-color: white;
  color: black;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid rgba(161, 108, 40, 1);
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background-color: rgba(161, 108, 40, 0.1);
  }
`;
