/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { Property } from 'csstype';
import { useIsCompact } from 'utilities/breakpoints';
//import { white, black, defaultTextColor, linkColor } from 'Shared/Style';

export enum ThemeType {
  White,
  Dark,
}

interface Theme {
  type: ThemeType;
  mainBackgroundColor: Property.BackgroundColor;
  mainTextColor: Property.Color;
  // linkColor: Property.Color;
}

type ThemeProps = {
  children?: React.ReactNode;
};

function themeFactory(colorTheme: Theme): React.FunctionComponent<ThemeProps> {
  return function Theme({ children }: ThemeProps) {
    const isCompact = useIsCompact();
    const theme = useMemo(() => ({ ...colorTheme, isCompact }), [isCompact]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  };
}

const whiteTheme: Theme = {
  type: ThemeType.White,
  mainBackgroundColor: 'white',
  mainTextColor: 'black',
  // linkColor,
};

const darkTheme: Theme = {
  type: ThemeType.Dark,
  mainBackgroundColor: 'white',
  mainTextColor: 'black',
  // linkColor,
};

export const WhiteTheme = themeFactory(whiteTheme);
export const DarkTheme = themeFactory(darkTheme);

export const ThemeByContex = ({ children }: ThemeProps) => {
  // check state and return theme based on context value

  // eslint-disable-next-line no-constant-condition
  const ThemePalatte = 1 === 1 ? WhiteTheme : DarkTheme;

  return (
    <ThemePalatte>
      <Container>{children}</Container>
    </ThemePalatte>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.mainBackgroundColor};
  color: ${({ theme }) => theme.mainTextColor};
`;
