import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const NotFoundPage: React.FC = () => (
  <div>
    <Row>
      <Col className="text-center">
        The page you were looking for could not be found. Take a cup of coffe and rethink your
        decision.
      </Col>
    </Row>
  </div>
);
