import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { allUserRoles } from 'utilities/constants';

export type ApplicationUser = {
  role: allUserRoles;
  userName: string;
  Id: number; // change to public id later
};

export type CurrentUser = {
  user: ApplicationUser;
};

export const initalUserState: CurrentUser = {
  user: {
    Id: 0,
    userName: '',
    role: allUserRoles.None,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initalUserState,
  reducers: {
    switchUser: (state, action: PayloadAction<ApplicationUser>) => {
      console.log('action', action);
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { switchUser } = userSlice.actions;

export default userSlice.reducer;
