import { ImagePreview, ImageUrlViewModel, UrlViewModel } from './Image';
import VideoScreenshot from './VideoScreenShot';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export type PreviewProps = {
  file: File;
  src: UrlViewModel;
  name: string;
};

export type MediaProps = {
  type: string;
  src: UrlViewModel;
  name: string;
};

export const Preview: React.FC<PreviewProps> = (props) => {
  const { src, file, name } = props;

  const fileExtension = props.file.name.split('.').pop()?.toLowerCase();
  const fileName = props.file.name;

  // const Video = () => <VideoPreview src={src} name={fileName} type={fileExtension} />;
  const Image = () => <ImagePreview src={src} name={fileName} type={fileExtension} />;

  const Comp = Image();

  return <StyledCol>{Comp}</StyledCol>;
};

const StyledCol = styled(Col)`
  max-width: ${({ theme }) => (theme.isCompact ? '100%' : '30%')};
  flex: 1 0 auto;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
`;
