import { Preview } from 'components/Media';
import React, { useEffect, useState } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Button, Container, Form, FormLabel, Row } from 'react-bootstrap';
import styled from 'styled-components';
import useForm from 'hooks/useForm';
import { MethodTypes } from 'hooks/useAxios';
import { generateScreenshots } from 'utilities';
import { ImageUrlViewModel, UrlViewModel } from 'components/Media/Image';
import { backendUrl } from 'config/constants';

type TrainingFile = {
  file: File;
  src: UrlViewModel;
  name: string;
};

export const Upload = () => {
  const [files, setFiles] = useState<TrainingFile[]>([]);
  const [screenshots, setScreenshots] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const { handleChange, handleSubmit } = useForm(`${backendUrl}upload`, MethodTypes.POST);

  console.log('url', `${backendUrl}/upload`);

  const uploadFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const uploads: TrainingFile[] = [];

    files.forEach((file) => {
      const reader = new FileReader();
      const img = new Image();

      reader.onload = (ev: ProgressEvent<FileReader>) => {
        if (ev.target && ev.target.result) {
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            const imageViewModel: UrlViewModel = {
              url: img.src,
              width: img.width,
              height: img.height,
              intrinsicSize: `${img.width}x${img.height}`,
            };
            const upload: TrainingFile = {
              file,
              src: imageViewModel,
              name: file.name,
            };
            uploads.push(upload);
            if (uploads.length === files.length) {
              setFiles(uploads);
            }
          };
        }
      };

      reader.readAsDataURL(file);
    });

    // const screenShoots = await generateScreenshots(files);
    // setScreenshots(screenShoots);
    handleChange(event);
  };

  return (
    <StyledContainer>
      <Row>
        <Main>
          <h2>Add a new A new file</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label htmlFor="attendantUpload">
                <span>{'Upload an image'}</span>
              </Form.Label>
              <Form.Control
                id="attendantUpload"
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={uploadFiles}
                name="file"
              />
            </Form.Group>
            <button>Trigger</button>
          </Form>
        </Main>
      </Row>
      <Row>
        {files.length > 0 &&
          files.map((file, index) => (
            <>
              <Preview src={file.src} name={file.name} file={file.file} />
              {/* <div>Want a cover image?</div>
              <Form.Check reverse label="2" name="group1" type={'radio'} /> */}
            </>
          ))}
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  max-width: 3000px;
`;

const Main = styled.div`
  margin: auto;
  margin-top: 20px;
  width: 450px;
  height: 350px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px;
  border: 1px solid rgba(161, 108, 40, 1);
  border-radius: 20px;
`;
