import React from 'react';
import { StartPage, Login, Upload, AllImages, Register } from 'pages/exportPages';

export enum RouteType {
  Public,
  Private,
  Edit,
}

export const routePoints = {
  start: '/start',
  person: '/person',
  login: '/login',
  upload: '/upload',
  images: '/images',
  register: '/register',
};

export type RouteInfo = {
  id: number;
  path: string;
  component: React.FC;
  text: string;
  type: RouteType;
};

export const allRoutes: Array<RouteInfo> = [
  {
    id: 1,
    path: routePoints.start,
    component: StartPage,
    text: 'Start',
    type: RouteType.Public,
  },

  {
    id: 2,
    path: routePoints.login,
    component: Login,
    text: 'login',
    type: RouteType.Public,
  },

  {
    id: 3,
    path: routePoints.upload,
    component: Upload,
    text: 'upload',
    type: RouteType.Edit,
  },
  {
    id: 4,
    path: routePoints.images,
    component: AllImages,
    text: 'images',
    type: RouteType.Edit,
  },

  {
    id: 5,
    path: routePoints.register,
    component: Register,
    text: 'register',
    type: RouteType.Public,
  },

  // {
  //   id: 2,
  //   path: routePoints.search,
  //   component: SearchPage,
  //   text: 'Search',
  // },

  // {
  //   id: 3,
  //   path: routePoints.gallery,
  //   component: GalleryPage,
  //   text: 'Gallery',
  // },
];
