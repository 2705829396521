import React, { Suspense, lazy } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { allUserRoles } from 'utilities/constants';
import { checkAuth, checkRole } from 'utilities/roleUtillity';

const EditLayout = lazy(() => import('components/Layout/EditLayout'));

export const EditRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        checkRole(allUserRoles.Support) ? (
          <Suspense fallback={<div>Loading...</div>}>
            <EditLayout>
              <Component {...props} />
            </EditLayout>
          </Suspense>
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <div>We could not find what you were looking for</div>
          </Suspense>
        )
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
);
