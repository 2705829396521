import useAxios, { MethodTypes } from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { backendUrl } from 'config/constants';
import { AxiosRequestConfig } from 'axios';
import { NavLink } from 'react-router-dom';

type Params = {
  guidId: string;
};

export const Verified: React.FC = () => {
  const [verified, setVerified] = useState<Boolean>(false);
  const [callAxiosFn, axiosState] = useAxios<Boolean>();

  const { guidId } = useParams<Params>();

  useEffect(() => {
    if (axiosState.status === 0 && !axiosState.loading && !axiosState.error) {
      const obj: AxiosRequestConfig = {
        method: MethodTypes.POST,
        url: `${backendUrl}verify`,
        data: { guidId },
      };
      callAxiosFn(obj);
    } else if (axiosState.status === 200) setVerified(true);
  }, [axiosState, callAxiosFn, guidId, setVerified]);

  return (
    <>
      {axiosState.error && (
        <div>Something happened, please try again later or contact support here</div>
      )}
      {axiosState.loading && <div>Verifying...</div>}
      {verified && (
        <div>
          You have verified your account, please <NavLink to="/login">Login here</NavLink>
        </div>
      )}
    </>
  );
};
