import React from 'react';
import { Routes } from 'routes/Routes';
import { allRoutes, RouteType } from 'routes/AllRoutes';
import { Router, Switch } from 'react-router-dom';
import history from 'Shared/history';
import { StartPage, NotFoundPage, Verified } from 'pages/exportPages';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setupResizeListener } from 'utilities/breakpoints';
import { store, RootState } from './redux-tk/store';
import styled from 'styled-components';
import { PrivateRoute } from 'routes/PrivateRoute';
import { EditRoute } from 'routes/EditRoute';
import { checkAuth } from 'utilities/roleUtillity';
import { useSelector } from 'react-redux';
import { allUserRoles } from 'utilities/constants';

const App: React.FC = () => {
  setupResizeListener(store);
  const user = useSelector((state: RootState) => state.currentUser.user);

  console.log('the user', user);

  const filteredRoutes = allRoutes.filter(
    (f) =>
      (f.type === RouteType.Public && user.Id === 0) ||
      (f.type === RouteType.Private && user.Id !== 0) ||
      (f.type === RouteType.Edit && user.role === allUserRoles.Admin)
  );

  console.log('ROUTES', filteredRoutes);

  return (
    <Main role="main">
      <Router history={history}>
        <Switch>
          {filteredRoutes.map((prop) =>
            prop.type === RouteType.Public && !checkAuth() ? (
              <Routes path={prop.path} component={prop.component} key={prop.id} />
            ) : prop.type === RouteType.Private ? (
              <PrivateRoute path={prop.path} component={prop.component} key={prop.id} />
            ) : (
              <EditRoute path={prop.path} component={prop.component} key={prop.id} />
            )
          )}
          <Routes path="/verified/:guidId" component={Verified} />
          <Routes exact path="/" component={StartPage} />

          <Routes component={NotFoundPage} />
        </Switch>
      </Router>
    </Main>
  );
};

export default App;

const Main = styled.div`
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.7);
`;
