import React, { Suspense, lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkAuth } from 'utilities/roleUtillity';

const PrivateLayout = lazy(() => import('components/Layout/PrivateLayout'));

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Suspense fallback={<div>Loading...</div>}>
          <PrivateLayout>
            <Component {...props} />
          </PrivateLayout>
        </Suspense>
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
);
