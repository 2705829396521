import {
  Format,
  CustomImageAppearance,
  ImageMode,
  Placeholder,
  NoImage,
  Size,
  getPresetFromBreakpoint,
  srcSet,
  useCurrentBreakpoint,
  imageUrl,
  ImageBase,
} from 'utilities/breakpoints';
import { MediaProps } from './index';
import { Ratio } from 'Shared/AspectRatio';
import styled from 'styled-components';

export interface UrlViewModel {
  url: string;
  width: number;
  height: number;
  intrinsicSize: string;
}

export interface ImageUrlViewModel extends UrlViewModel {
  width: number;
  height: number;
  intrinsicSize: string;
}

export type ImagePropType = React.ImgHTMLAttributes<HTMLImageElement> & {
  myCustomSrc: UrlViewModel;
  preset?: Size;
  lazy?: boolean;
  format?: Format;
  mode?: ImageMode;
  padRatio?: Ratio;
  fastLoadImage?: boolean;
  shouldFill?: boolean;
  customImageAppearance?: CustomImageAppearance;
  onLoadSuccessful?: () => void;
};

export const ImagePreview: React.FC<MediaProps> = (props) => {
  const { src, name } = props;

  // Create an object of type UrlViewModel from the src string

  return (
    <>
      <img src={src.url} alt="hej" />
      <Image myCustomSrc={src} alt="hej" />
      <span>{name || 'Upload an image'}</span>
    </>
  );
};

type DefaultPropType = {
  ratio?: Ratio;
  preset?: Size;
  lazy?: boolean;
  fill?: boolean;
};

type PropType = DefaultPropType & ImagePropType;

export const Image: React.FC<PropType> = (props) => {
  const {
    myCustomSrc,
    preset,
    format,
    lazy = true,
    mode,
    padRatio,
    fastLoadImage,
    customImageAppearance,

    ...restProps
  } = props;
  const breakpoint = useCurrentBreakpoint();
  const width = (myCustomSrc as ImageUrlViewModel)?.width;
  const internalPreset = preset || getPresetFromBreakpoint(breakpoint, width);
  const ImgComponent = ImageBase;

  if (!myCustomSrc) return <Placeholder src={NoImage} {...restProps} />;

  if (internalPreset && !('srcSet' in restProps)) {
    const srcSetAttribute = srcSet(
      myCustomSrc.url,
      internalPreset,
      format,
      mode,
      customImageAppearance,
      width
    );
    if (srcSetAttribute) restProps.srcSet = srcSetAttribute;
  }

  return (
    <ImgComponent
      src={imageUrl(
        myCustomSrc.url,
        internalPreset,
        format,
        mode,
        width,
        customImageAppearance,
        padRatio
      )}
      // fastLoadImage={fastLoadImage}
      // originalSrc={src.url}
      {...restProps}
    />
  );
};

export default styled(Image);
